export const data = [
    {
        id:1, 
        title: "XOTIC",
        year: 2015,
        description: "The first ROV that we designed and manufactured for our first participation in the MATE ROV Competition.",
        feature1: "An acrylic structure that allows water flow through the walls guaranteeing good movement underwater.",
        feature2:"A suction algae sampling mechanism.",
        feature3:"A device for pumping water through a pipe.",
        feature4:"An underwater vision system with two degrees of freedom to observe the marine environment.",
        price:"4,127.41 (USD)",
    },
    {
        id:2, 
        title: "ZEUS",
        year: 2016,
        description: "This is our second ROV designed and manufactured for the MATE ROV Competition.",
        feature1: "Modular design (with interchangeable tools).",
        feature2:"Custom 3D parts.",
        feature3:"It has a GoPro camera.",
        feature4:"Friendly data communication.",
        price:"3,648.48 (USD)",
    },
    {
        id:3, 
        title: "BALAM",
        year: 2017,
        description: "This is our third ROV designed and manufactured for the MATE ROV Competition.",
        feature1: "An acrylic structure.",
        feature2:"Compact design.",
        feature3:"It has 4 cameras for underwater vision.",
        feature4:"3D printed enclosures for each electronic speed control (ESC).",
        price:"5,313.41 (USD)",
    },
    {
        id:4, 
        title: "AXOLOTL",
        year: 2018,
        description: "For our fourth participation in the MATE ROV Competition we designed and manufactured this.",
        feature1: "Underwater LED lights.",
        feature2:"It has 2 HD cameras.",
        feature3:"It has a 3D printed pneumatic gripper and spinner.",
        feature4:"Aluminum and acrylic frame.",
        price: "5,313.41 (USD)",
    },
    {
        id:5, 
        title: "ACOATL",
        year: 2019,
        description: "For our fifth year participating in the MATE ROV Competition, we designed and manufactured this.",
        feature1: "Uniform buoyancy and compact structure.",
        feature2:"It has an underwater endoscopic camera.",
        feature3:"Pneumatic gripper.",
        feature4:"It has a trout storage, transport and release device.",
        price:"4,103.74 (USD)",
    },
    {
        id:6, 
        title: "KOLOP",
        year: 2021,
        description: "For our sixth year participating in the MATE ROV Competition, we designed and manufactured this.",
        feature1: "A SMW design (Simple, Modular and Well Made).",
        feature2:"Geometry and Mexican folklore prints in the structure.",
        feature3:"Custom protectors for the propellers.",
        feature4:"Pressure relief valves that allows it to work at greater depths.",
        price:"2,751.27 (USD)",
    },
    {
        id:7, 
        title: "ATZIN",
        year: 2022,
        description: "For our seventh year participating in the MATE ROV Competition, we designed and manufactured this.",
        feature1: "It has a modular design. ",
        feature2:"Illuminated status indicators and safety decals.",
        feature3:"It has a strong grip forceps, a double jaw forceps and a pin ejector.",
        feature4:"3D printed propeller guards.",
        price:"1,597.9 (USD)",
    },
    {
        id:8, 
        title: "ATZIN 2.0",
        year: 2023,
        description: "For our eighth year participating in the MATE ROV Competition, we designed and manufactured this.",
        feature1: "It has a frame made of PolyAl, a sustainable Cellulose Aluminum Plastic Composite construction panel. ",
        feature2:"It has an eight thruster layout that provides high maneuverability and better displacement.",
        feature3:"Two 3D printed float containers.",
        feature4:"Slim profile tensor relief system and flexible bands that fix the electronic enclosures in place.",
        price:"3,353.24 (USD)",
    },
    {
        id:9, 
        title: "TZILACATZIN",
        year: 2024,
        description: "This is our ninth year participating in the MATE ROV Competition. Meet our new rov!",
        feature1: "It has a 3D printed double central claws. ",
        feature2:"It has a tensor relief that helps the rov move smoothly underwater.",
        feature3:"The frame and tool deck are made of PolyAl which helps to increase bouyancy. ",
        feature4:"It has an eight-thruster configuration that offers excellent maneuverability with enhanced displacement and precision for underwater tasks.",
        price:"4,772.88 (USD)",
    }

];